.custom-purple-tag {
  background-color: #7e57c2 !important;
  border: 1px solid #512da8 !important;
  color: #ffffff !important;
  font-size: 0.85rem;
  border-radius: 1rem;
  padding: 0.3rem 0.75rem;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.2s ease;
  text-transform: capitalize;
}

.custom-purple-tag:hover {
  background-color: #674ab7 !important;
  cursor: default;
}

.custom-purple-tag .p-tag {
  background: none;
  padding: 0;
}
