@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.min.css';
@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex.css';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('./assets/css/p-cad.css');
@import url('./assets/css/p-fieldset.css');
@import url('./assets/css/p-multiselect.css');
@import url('./assets/css/p-button.css');
@import url('./assets/css/p-tag.css');

body {
  background: #f1f1f1;
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
}

.link {
  cursor: pointer;
  text-decoration: underline;
  color: #85a5df;
}
.link:hover {
  color: #3b82f6;
}
.link:active {
  color: #1d4ed8;
}

.txt-logo {
  font-size: 1.6rem;
  color: #3c4844;
}

.txt-logo .bold {
  color: #7e57c2;
}
