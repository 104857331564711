.p-card-content {
  flex: 1;
}

.p-card .p-card-title {
  font-size: 1.2rem;
  font-weight: 700;
  color: #3c4844;
}

.p-card .p-card-subtitle {
  font-size: 0.95rem;
  color: #7e57c2;
  margin-bottom: 0.5rem;
}
