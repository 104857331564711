.custom-purple-button {
  background-color: #7e57c2;
  border: none;
  color: #fff;
}

.custom-purple-button:hover {
  background-color: #5e40a4;
}

.custom-purple-tag {
  background-color: #7e57c2;
  color: #fff;
  border-radius: 1rem;
  padding: 0.3rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 500;
}

.custom-secondary-button {
  background-color: #f3e9c5;
  color: #3c4844;
  border: 1px solid #e0d6b5;
  font-weight: 500;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
}

.custom-secondary-button:hover {
  background-color: #e0d6b5;
  color: #3c4844;
  border-color: #d2c4a0;
}

.upload-purple .p-button {
  background-color: #7e57c2;
  border-color: #7e57c2;
  color: #fff;
}

.upload-purple .p-button:hover {
  background-color: #6d4db3;
  border-color: #6d4db3;
}
